import Seo from 'src/components/seo/Seo'

import Recall from '../../components/servicos/ServicesPage/Recall'

function Page() {
  return (
    <>
      <Seo title="Recall | Decathlon" />
      <Recall />
    </>
  )
}

export default Page
